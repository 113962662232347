@root-entry-name: "default";
@import "ant-styles/colorPalette.less";

@gray-base: @dng-gray;
@white: @dng-white;
@gray-0: #f8f8f8;
@gray-1: #f1f1f1;
@gray-2: #eaeaea;
@gray-3: #dddddd;
@gray-4: #c1c1c1;
@gray-5: @dng-gray;
@gray-6: #898989;
@gray-7: #6e6e6e;
@gray-8: #525252;
@gray-9: #373737;
@gray-10: #1b1b1b;
@black: @dng-black;

@dng-white: #ffffff;
@dng-grey: #a6a6a6;
@dng-black: #000000;
@dng-cyan: #017DA5;
@dng-dark-blue: #004d67;
@dng-green: #15af97;
@dng-yellow: #edc800;
@dng-orange: #e77707;
@dng-red: #e7615a;
@dng-grey-2: #312f32;

@dng-gray: @dng-grey;

@ipHeaderHeight: 43px;
@text-color-secondary: @dng-grey;

@primary-1: color(~`colorPalette('@{primary-6}', 1) `);
@primary-2: color(~`colorPalette('@{primary-6}', 2) `);
@primary-3: color(~`colorPalette('@{primary-6}', 3) `);
@primary-4: color(~`colorPalette('@{primary-6}', 4) `);
@primary-5: color(~`colorPalette('@{primary-6}', 5) `);
@primary-6: #1296b3;
@primary-7: color(~`colorPalette('@{primary-6}', 7) `);
@primary-8: @dng-dark-blue; // == @primary-color == color(~`colorPalette('@{primary-6}', 8) `) - actually, the colorPalette result is #004d66 and not #004d67, but who cares?
@primary-9: color(~`colorPalette('@{primary-6}', 9) `);
@primary-10: color(~`colorPalette('@{primary-6}', 10) `);

@extra-light-blue-0: #eaf0f2;
@extra-light-blue-1: #f2fafb;
@extra-light-blue-2: #e9f6f8;
@extra-light-blue-3: #32aabf;
@blue-base: @dng-cyan;
@red-base: @dng-red;
@yellow-base: @dng-yellow;
@orange-base: @dng-orange;

@success-color: #3A9E86;
@warning-color: #EB7B00;
@error-color: #DF4948;
@normal-color: @gray-3;
@link-color: @blue-7;

@primary-color: @dng-dark-blue;
@info-color: @dng-cyan;
@text-color-secondary-dark: fade(@white, 90%);

@autofocus-light-blue: #e3f2fd;
@autofocus-light-red: #fde3e3;
@font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

@headerHeight: 56px;
@primary-color-reserve: @dng-dark-blue;
@layout-header-background: @primary-color;
@layout-header-padding: 0;
@layout-header-height: 48px;
@layout-sider-background: @layout-header-background;
@layout-body-background: @white;
@layout-trigger-background: @layout-sider-background;

@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-collapsed-width: 50px;
@menu-dark-color: @text-color-secondary-dark;
@menu-dark-bg: @layout-sider-background;
@menu-dark-arrow-color: #fff;
@menu-dark-submenu-bg: tint(@layout-header-background, 20%);
@menu-dark-highlight-color: #fff;
@menu-dark-item-active-bg: lighten(@primary-color, 50%);

@breadcrumb-font-size: @font-size-lg;

@zindex-modal: 1005;
@zindex-modal-mask: 1005;

// Ant border overriding
@border-radius-base: 8px;
@btn-border-radius-base: 2px;
@btn-border-radius-sm: 2px;
@checkbox-border-radius: 2px;

@progress-default-color: @primary-color;

@text-default: @gray-8;
@text-default-dark: @gray-9;
@text-default-light: @gray-7;
@text-colored: @primary-color;

@anchor-color: @gray-6;

@icon-default: @gray-8;
@icon-colored: @primary-color;

@table-header-default-bg: @gray-2;
@table-header-default-border-color: @gray-4;
@table-header-selected-bg: @gray-3;
@table-header-selected-border-color: @gray-4;
@table-header-hover-bg: @gray-3;
@table-header-hover-border-color: @gray-4;

@table-header-splitter-color: @gray-4;

@table-row-default-border-color: @gray-4;
@table-row-default-color: @gray-8;

@table-row-default-bg: @gray-1;
@table-row-selected-bg: @gray-2;
@table-row-hover-bg: @blue-1;
@table-row-success-bg: @blue-1;
@table-row-error-bg: @red-1;

@page-header-border-color: @gray-4;

@uploader-icon-fill-color: @primary-color;
@uploader-bg: @gray-3;
@uploader-hover-border-color: @primary-color;

@scoring-bg: @gray-3;
@scoring-border-color: @gray-5;

@collapse-expand-button-bg: @gray-3;
@collapse-expand-button-color: @gray-9;
@collapse-expand-button-hover-bg: @blue-1;
@collapse-expand-button-hover-color: @primary-color;

@dropdown-text-color: @primary-color;
@dropdown-hover-text-color: @blue-5;
@dropdown-focus-text-color: @blue-7;
@dropdown-menu-hover-bg: @blue-1;
@dropdown-menu-active-bg: @gray-3;

@sider-color: @gray-1;

@layout-header-bg: @gray-3;

@template-popover-color: @gray-8;
@template-popover-font-size: 12px;
@template-popover-title-font-size: 14px;
@template-popover-importance-font-size: 20px;
@template-popover-importance-color: @gray-9;

@template-popover-error-color: @red-6;

:root {
  --dataset-sider-width: 320px;
}
